/***
    index.html > initloading.html 
        ng-controller = 'initloading'
***/

(function() {

    angular
        .module('App')
        .controller('index', IndexController);

    IndexController.$inject = ['$scope','$http','$timeout','kissApiService'];

    function IndexController($scope, $http, $timeout, kissApiService) {
        //TODO check version status?

        checkUserStates();

        function checkUserStates() {
            checkAuth();
        }

        function checkAuth() {
            console_log('checkAuth');

            loading_modal.show();
            $http.post('auth/checkAuth')
                .success(function (data, status, headers, config) {
                    console_log(data);
                    if (!data['login']) {
                        loading_modal.hide();
                        kissUtil.callNavigator("login/login.html");
                        return;
                    }
                    kissUtil.setName(data['examineeName']);
                    loading_modal.hide();
                    kissUtil.callNavigator("selectProgram.html");
                })
                .error(function (data, status, headers, config) {
                    kissUtil.alert('error','通信エラーが発生しました');
                    loading_modal.hide();
                    //TODO move to error page
                });
        }
    }

}());
