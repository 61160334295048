/***
 * html/diagnosis_content.html
 * ng-controller = 'DiagnosisContent'
 ***/

(function() {

    angular
        .module('App')
        .controller('DiagnosisContent', DiagnosisContentController);

    DiagnosisContentController.$inject = ['$http','$q','$scope','$timeout','kissApiService'];

    function DiagnosisContentController($http, $q, $scope, $timeout, kissApiService) {
        //var $pageContents = $('.pageContents');
        var view = $scope;
        var diagnosisData = [];
        var answers = [];
        var currentNum = 0;
        var $sortableList = $('#sortable');
        var sortOrder;

        initializeSortableArea();
        prepareDiagnosis();

        function prepareDiagnosis() {
            console_log('prepare diagnosis');

            //TODO local storage diagnosis, currentNum, answers check

            try {
                var hasLocalData = checkLocalStorage();
            } catch (e) {
                alert('ブラウザをプライベートモードにしないでください');
                window.location.reload();
            }
            if (hasLocalData) {
                loading_modal.show();
                try {
                    getDataFromLocalStorage(['diagnosisData', 'answers', 'currentNum']);
                } catch (e) {
                    alert('ブラウザをプライベートモードにしないでください');
                    window.location.reload();
                }
                ons.ready(function() {
                }, $timeout(function() {
                    preparePageContents();
                    loading_modal.hide();

                    ons.createPopover('popover.html').then(function(popover) {
                        $scope.popover = popover;
                    });
                    $scope.showHelp = function(e) {
                        $scope.popover.show(e);
                    };

                }),0);
                return;
            }

            var id = kissUtil.getDiagnosisProgram().id;

            loading_modal.show();
            $http.post('diag/diagnosis/' + id)
                .success(function (data, status, headers, config) {
                    console_log(data);

                    if (data['hasError']) {
                        kissUtil.alert('',data['message']);
                        //TODO logout
                        return;
                    }

                    diagnosisData = data['data'];
                    try {
                        setDataToLocalStorage(['diagnosisData', 'answers', 'currentNum']);
                    } catch (e) {
                        alert('ブラウザをプライベートモードにしないでください');
                        window.location.reload();
                    }

                    //TODO local storage diagnosis, currentNum, answers save

                    preparePageContents();
                    loading_modal.hide();
                })
                .error(function (data, status, headers, config) {
                    kissUtil.alert('error','通信エラーが発生しました');

                    //TODO move to error page
                    loading_modal.hide();
                });
        }

        function checkLocalStorage() {
            if(localStorage.getItem('diagnosisData')) {
                return true;
            } else {
                return false;
            }
        }

        function preparePageContents() {
            var question;

            if (currentNum <= 0) {
                question = diagnosisData[0];

                $scope.preBtn.setDisabled(true);
                view.onclick_prevQuestion = null;
                view.nextButton = '次へ';
                view.onclick_nextQuestion = getNextQuestion;
            }

            if (currentNum >= diagnosisData.length - 1) {
                question = diagnosisData[diagnosisData.length - 1];

                view.nextButton = '完了';
                view.onclick_nextQuestion = submitAnswer;
                view.onclick_prevQuestion = getPreQuestion;
            }

            if (currentNum > 0 && currentNum < diagnosisData.length - 1) {
                question = diagnosisData[currentNum];

                if ($scope.preBtn.isDisabled()) {
                    $scope.preBtn.setDisabled(false);
                    view.onclick_prevQuestion = getPreQuestion;
                }
                if (view.nextButton != '次へ') {
                    view.nextButton = '次へ';
                    view.onclick_nextQuestion = getNextQuestion;
                    view.onclick_prevQuestion = getPreQuestion;
                }
            }

            var keys = Object.keys(question);
            keys.forEach(function(e, i, a) {
                keys[i] = e - '0';
            });
            keys.sort(function(a, b) { return (a - b); });
            console_log(keys);

            var answer = answers[currentNum];
            if (answer == undefined) {
                answer = {};
                for (var i = 0; i < keys.length; i++) {
                    answer[keys[i]] = i + 1;
                }
            }

            view.currentState = (currentNum + 1) + "/" + diagnosisData.length;

            var data = [];
            var id = [];
            view.title = 'Q' + (currentNum + 1);
            for (var j = 0; j < keys.length; j++) {
                id[answer[keys[j]] - 1] = keys[j];
                data[answer[keys[j]] - 1] = question[keys[j]]['label'] + '. ' + question[keys[j]]['content'];
            }

            $sortableList.html(getSortableItemsHtml(keys, data, id));
            sortOrder = $sortableList.sortable('toArray');
        }

        function getSortableItemsHtml(keys, data, id) {
            var html = '';

            for (var i = 0; i < keys.length; i++) {
                html += '<li id="' + id[i] + '" class="listButton__item mt5">' + data[i] + '</li>';
            }
            return html;
        }

        function saveAnswer() {
            console_log(sortOrder);

            //TODO save last answer into local storage

            answers[currentNum] = {};
            for (var i = 0; i < sortOrder.length; i++) {
                answers[currentNum][sortOrder[i]] = i + 1;
            }

            console_log(answers);
        }

        function submitAnswer() {
            kissUtil.alertConfirm('回答を送信しますか？', function() {
                saveAnswer();

                prepareAnswer();

                console_log('submit answer');

                var id = kissUtil.getDiagnosisProgram().id;

                loading_modal.show();
                $http.post('diag/answer/' + id, {
                        data: answers
                    })
                    .success(function (data, status, headers, config) {
                        console_log(data);

                        if (data['hasError']) {
                            kissUtil.alert('',data['message']);
                            loading_modal.hide();

                            //TODO logout
                            return;
                        }
                        try {
                            deleteLocalStorageData();
                        } catch (e) {
                            alert('ブラウザをプライベートモードにしないでください');
                            window.location.reload();
                        }
                        loading_modal.hide();
                        kissUtil.callNavigator('diagnosis/end.html');
                    })
                    .error(function (data, status, headers, config) {
                        kissUtil.alert('error','通信エラーが発生しました');
                        loading_modal.hide();
                        //TODO move to error page
                    });
            });
        }

        function prepareAnswer()
        {
            var tmp = [];
            answers.forEach(function(e, i, a) {
                for (var key in e) {
                    if (e.hasOwnProperty(key)) {
                        var answer = {};
                        answer.id = parseInt(key);
                        answer.answer = e[key];
                        tmp.push(answer);
                    }
                }
            });

            answers = tmp;
        }

        function getPreQuestion() {
            saveAnswer();

            currentNum--;
            try {
                setDataToLocalStorage(['currentNum', 'answers']);
            } catch (e) {
                alert('ブラウザをプライベートモードにしないでください');
                window.location.reload();
            }
            preparePageContents();
        }

        function getNextQuestion() {
            saveAnswer();

            currentNum++;
            try {
                setDataToLocalStorage(['currentNum', 'answers']);
            } catch (e) {
                alert('ブラウザをプライベートモードにしないでください');
                window.location.reload();
            }
            preparePageContents();
        }

        function initializeSortableArea() {
            $timeout(function() {
                jQuery("#sortable").sortable({
                    axis: "y",
                    update: function(e, ui) {
                        sortOrder = $sortableList.sortable('toArray');
                    }
                });
            },0);
        }

        function deleteLocalStorageData() {
            localStorage.clear();
        }

        function getDataFromLocalStorage(data) {
            data.forEach(function(data) {
                switch (data) {
                    case 'diagnosisData':
                        diagnosisData = JSON.parse(localStorage.getItem('diagnosisData'));
                        break;
                    case 'answers':
                        answers = JSON.parse(localStorage.getItem('answers'));
                        break;
                    case 'currentNum':
                        currentNum = parseInt(localStorage.getItem('currentNum'));
                        break;
                    default:
                        //TODO エラー
                        break;
                }
            });
        }

        function setDataToLocalStorage(data) {
            data.forEach(function(data) {
               switch (data) {
                   case 'diagnosisData':
                       localStorage.setItem('diagnosisData', JSON.stringify(diagnosisData));
                       break;
                   case 'answers':
                       localStorage.setItem('answers', JSON.stringify(answers));
                       break;
                   case 'currentNum':
                       localStorage.setItem('currentNum', currentNum);
                       break;
                   default:
                       //TODO エラー
                       break;
               }
            });
        }
    }

}());