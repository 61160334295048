/**
 * Created by KazukiSugita on 2016/02/08.
 */

(function() {

    angular
        .module('App')
        .controller('ResultDerailment', ResultDerailmentController);

    ResultDerailmentController.$inject = ['$http', '$scope','$timeout', 'ResultData'];

    function ResultDerailmentController($http, $scope, $timeout , ResultData) {
        var view = $scope;

        setData();

        function setData() {

            var data = ResultData.derailment;

            view.derailment_comments = data['derailment_comments'];
            view.derailment_params = data['derailment_params'];
        }
    }

}());