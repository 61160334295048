/**
 * Preated by KazukiSugita on 2016/02/08.
 */

(function() {

    angular
        .module('App')
        .controller('ResultProblem', ResultProblemController);

    ResultProblemController.$inject = ['$http', '$scope','$timeout', 'ResultData'];

    function ResultProblemController($http, $scope, $timeout, ResultData) {
        var view = $scope;

        setData();

        function setData() {

            var data = ResultData.ability;

            view.problem_solving_comments = data['problem_solving_comments'];
            view.problem_solving_params = data['problem_solving_params'];
            view.problem_solving_related_behaviour_params = {};

            var related = data['problem_solving_related_behaviour_params'];
            Object.keys(related).forEach(function(key) {
                view.problem_solving_related_behaviour_params[key] = related[key]*10;
            });
        }
    }

}());