/**
 * Created by KazukiSugita on 2016/02/08.
 */

(function() {

    angular
        .module('App')
        .controller('ResultBehaviour', ResultBehaviourController);

    ResultBehaviourController.$inject = ['$http', '$scope','$timeout', 'ResultData'];

    function ResultBehaviourController($http, $scope, $timeout, ResultData) {
        var view = $scope;

        setData();

        function setData() {

            var data = ResultData.behaviour;

            view.recognition_comments = data['recognition_comments'];
            view.relation_comments = data['relation_comments'];
            view.management_comments = data['management_comments'];
            view.expression_comments = data['expression_comments'];

            var reco = data['recognition_params'];
            var rela = data['relation_params'];
            var mana = data['management_params'];
            var expr = data['expression_params'];

            var chartList = [
                ['result01','変化性', '楽観性', '安定性', '自立性', '自己効力', reco['B1_1'], reco['B1_2'], reco['B1_3'], reco['B1_4'], reco['B1_5'],'RadarChart01'],
                ['result02','目的性', '懐疑性', '柔軟性', '主張性', '親和性', rela['B2_1'], rela['B2_2'], rela['B2_3'], rela['B2_4'], rela['B2_5'],'RadarChart02'],
                ['result03','計画性', '徹底性', '常識性', '発想性', '直感性', mana['B3_1'], mana['B3_2'], mana['B3_3'], mana['B3_4'], mana['B3_5'],'RadarChart03'],
                ['result04','顕示性', '集団性', '迅速性', '単独性', '秩序性', expr['B4_1'], expr['B4_2'], expr['B4_3'], expr['B4_4'], expr['B4_5'],'RadarChart04']
            ];

            canvasGenerator(chartList);
        }

        function canvasGenerator(chartList) {
            for (var i=0 ; i<4 ; i++){
                var data = {
                    labels: [chartList[i][1], chartList[i][2], chartList[i][3], chartList[i][4], chartList[i][5]],
                    datasets: [
                        {
                            label: "My First dataset",
                            fillColor: "rgba(51,204,204,0.2)",
                            strokeColor: "rgba(51,204,204,1)",
                            pointColor: "rgba(51,204,204,1)",
                            pointStrokeColor: "#fff",
                            pointHighlightFill: "#fff",
                            pointHighlightStroke: "rgba(51,204,204,1)",
                            data: [chartList[i][6], chartList[i][7], chartList[i][8], chartList[i][9], chartList[i][10]]
                        }
                    ]
                };

                var option = {
                    scaleOverride: true,
                    scaleSteps : 5,
                    scaleStepWidth : 2,
                    scaleStartValue : 0,
                    scaleShowLabels: true
                };

                var ctx = document.getElementById(chartList[i][0]).getContext("2d");
                chartList[i][11] = new Chart(ctx).Radar(data, option);
            }
        }

    }

}());