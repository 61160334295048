(function() {

    angular
        .module('App')
        .controller('SelectProgram', SelectProgramController);

    SelectProgramController.$inject = ['$http','$scope','$timeout', 'kissApiService'];

    function SelectProgramController($http, $scope, $timeout, kissApiService) {
        var view = $scope;

        view.programs = [];
        view.hasProgram = false;
        view.onclick_logout = kissApiService.logout;
        view.onclick_program = setProgramUserStarted;

        getPrograms();

        function getPrograms() {
            console_log('getPrograms');

            loading_modal.show();
            $http.post('programs')
                .success(function (data, status, headers, config) {
                    console_log(data);

                    if (!data['hasPrograms']) {
                        kissUtil.alert('',data['message']);
                        loading_modal.hide();

                        //TODO logout
                        return;
                    }
                    view.hasProgram = true;
                    angular.copy(data['programs'], view.programs);
                    loading_modal.hide();
                })
                .error(function (data, status, headers, config) {
                    kissUtil.alert('error','通信エラーが発生しました');
                    loading_modal.hide();

                    //TODO move to error page
                });
        }

        function setProgramUserStarted(program) {
            try {
                checkDiffProgramIdInLocalStorage(program);
            } catch (e) {
                alert('ブラウザをプライベートモードにしないでください');
                window.location.reload();
            }
            kissUtil.setProgram(program);
            kissUtil.setDiagnosisProgram(undefined);
            kissUtil.setProgramQuestionnaire(undefined);
            kissApiService.checkProgram(program.id);
        }

        function checkDiffProgramIdInLocalStorage(pg) {
            var pgID = 'programId';
            if(localStorage.getItem(pgID) == undefined) {
                localStorage.setItem(pgID, pg.id);
            } else if(localStorage.getItem(pgID) != pg.id) {
                localStorage.clear();
                localStorage.setItem(pgID, pg.id);
            }
        }

    }

}());
