/**
 * Created by KazukiSugita on 2016/02/08.
 */

(function() {

    angular
        .module('App')
        .controller('ResultSalesConsulting', ResultSalesConsultingController);

    ResultSalesConsultingController.$inject = ['$http', '$scope','$timeout', 'ResultData'];

    function ResultSalesConsultingController($http, $scope, $timeout, ResultData) {
        var view = $scope;

        setData();

        function setData() {

            var data = ResultData.salesConsulting;

            view.practice_params = data['practice_params'];
            view.relation_params = data['relation_params'];
            view.proposal_params = data['proposal_params'];
        }
    }

}());