/**
 * Created by KazukiSugita on 2016/02/08.
 */

(function() {

    angular
        .module('App')
        .controller('ResultDriverSafety', ResultDriverSafetyController)
        .controller('ResultDriverConsumer', ResultDriverConsumerController)
        .controller('ResultDriverSociety', ResultDriverSocietyController)
        .controller('ResultDriverAutonomy', ResultDriverAutonomyController);

    ResultDriverSafetyController.$inject = ['$scope', 'ResultData'];
    ResultDriverConsumerController.$inject = ['$scope', 'ResultData'];
    ResultDriverSocietyController.$inject = ['$scope', 'ResultData'];
    ResultDriverAutonomyController.$inject = ['$scope', 'ResultData'];

    function ResultDriverSafetyController($scope, ResultData) {
        var view = $scope;
        setData();

        function setData() {
            var data = ResultData.ability;
            view.safety_params = data['safety'];
        }
    }

    function ResultDriverConsumerController($scope, ResultData) {
        var view = $scope;
        setData();

        function setData() {
            var data = ResultData.ability;
            view.consumer_params = data['consumer'];
        }
    }

    function ResultDriverSocietyController($scope, ResultData) {
        var view = $scope;
        setData();

        function setData() {
            var data = ResultData.ability;
            view.society_params = data['society'];
        }
    }

    function ResultDriverAutonomyController($scope, ResultData) {
        var view = $scope;
        setData();

        function setData() {
            var data = ResultData.ability;
            view.autonomy_params = data['autonomy'];
        }
    }

}());