(function() {

    angular
        .module('App')
        .service('kissApiService', kissApiService);

    kissApiService.$inject = ['$http','$q'];

    function kissApiService($http, $q) {

        //var baseUrl = 'https://dev2.kissco.bz';

        this.login = function(throwData) {
            //var path = '/api/auth';
            var path = 'json/';
            var deferred = $q.defer();

            $http({
                method: 'POST',
                url: path,
                data: throwData
            }).success(function(data){
                console_log(data);
                deferred.resolve(data);
            }).error(function(){
                deferred.reject('There was an error');
            });
            return deferred.promise;
        };

        this.checkProgram = function(id) {
            console_log('checkProgram:' + id);

            loading_modal.show();
            $http.post('program/' + id)
                .success(function (data, status, headers, config) {
                    console_log(data);
                    if (data['hasError']) {
                        kissUtil.alert('',data['message']);
                        loading_modal.hide();

                        //TODO logout
                        return;
                    }

                    if (!data['hasQuestionnaire']) {
                        //alert('アンケート項目がないかすべてのアンケート項目が終了した')
                    } else {
                        var program_questionnaire = data['program_questionnaire'];
                        if (data['questionnaireFinished']) {
                            //alert('アンケート項目 '+ program_questionnaire.name + ' へ答えが済んでいる')
                        } else {
                            switch (program_questionnaire.status) {
                                case 'inactive':
                                    //kissUtil.alert('','アンケート項目 ' + program_questionnaire.name + ' への答えはまだ開始していない');
                                    //alert('アンケート項目 ' + program_questionnaire.name + ' への答えはまだ開始していない');
                                    break;
                                case 'active':
                                    kissUtil.setProgramQuestionnaire(program_questionnaire);
                                    break;
                                case 'finished':
                                default:
                                    break;
                            }
                        }
                    }

                    if (!data['hasDiagnosis']) {
                        //alert('診断項目がないかすべての診断項目が終了した')
                    } else {
                        var diagnosis_program = data['diagnosis_program'];
                        if (data['diagnosisFinished']) {
                            //alert('診断項目 '+ diagnosis_program.name + ' へ答えが済んでいる')
                        } else {
                            switch (diagnosis_program.status) {
                                case 'inactive':
                                    //kissUtil.alert('','診断項目 ' + diagnosis_program.name + ' への答えはまだ開始していない');
                                    //alert('診断項目 ' + diagnosis_program.name + ' への答えはまだ開始していない');
                                    break;
                                case 'active':
                                    kissUtil.setDiagnosisProgram(diagnosis_program);
                                    break;
                                case 'finished':
                                default:
                                    break;
                            }
                        }
                    }

                    loading_modal.hide();
                    if (kissUtil.getProgramQuestionnaire() != undefined) {
                        //alert('アンケート項目 ' + program_questionnaire.name + ' への答えを開始する');
                        kissUtil.callNavigator("questionnaire/questionnaire.html");
                    } else if (kissUtil.getDiagnosisProgram() != undefined) {
                        //alert('診断項目 ' + diagnosis_program.name + ' への答えを開始する');
                        kissUtil.callNavigator("diagnosis/privatePolicy.html");
                    } else {
                        kissUtil.callNavigator("diagnosis/end.html");
                    }
                })
                .error(function (data, status, headers, config) {
                    kissUtil.alert('error','通信エラーが発生しました');
                    loading_modal.hide();

                    //TODO move to error page
                });
        };

        this.logout = function() {
            kissUtil.alertConfirm('ログアウトします。よろしいですか？', logout);
            function logout() {
                console_log('logout');

                loading_modal.show();
                $http.post('auth/logout')
                    .success(function (data, status, headers, config) {
                        console_log(data);

                        if (data['hasError']) {
                            kissUtil.alert('',data['message']);
                            loading_modal.hide();

                            //TODO logout
                            return;
                        }

                        loading_modal.hide();
                        kissUtil.callNavigator("login/login.html");
                    })
                    .error(function (data, status, headers, config) {
                        kissUtil.alert('error','通信エラーが発生しました');
                        loading_modal.hide();
                        //TODO move to error page
                    });
            }
        };

    }

}());