/**
 * Created by KazukiSugita on 2016/02/08.
 */

(function() {

    angular
        .module('App')
        .controller('ResultMain', ResultMainController);

    ResultMainController.$inject = ['$http','$scope','$timeout','ResultData','kissApiService'];

    function ResultMainController($http, $scope, $timeout, ResultData, kissApiService) {
        var view = $scope;
        var id = kissUtil.getProgram().id;

        view.name = kissUtil.getName();
        view.diagnosis_program_name = kissUtil.getProgram().name;
        view.exist = {
            'behaviour': ResultData.reportExist['behaviour'],
            'ability': ResultData.reportExist['ability'],
            'derailment': ResultData.reportExist['derailment'],
            'salesConsulting': ResultData.reportExist['salesConsulting'],
            'letter': ResultData.reportExist['letter'],
            'stress': ResultData.reportExist['stress'],
            'ability_items': ResultData.reportExist['ability_items']
        };
        view.toBehaviour = testBehaviour;
        view.toAbility = testAbility;
        view.toDerailment = testDerailment;
        view.toSalesConsulting = testSalesConsulting;
        view.toLetter = testLetter;
        view.toStress = testStress;
        view.onclick_logout = kissApiService.logout;

        function logout() {
            console_log('logout');

            loading_modal.show();
            $http.post('auth/logout')
                .success(function (data, status, headers, config) {
                    console_log(data);

                    if (data['hasError']) {
                        kissUtil.alert('',data['message']);
                        loading_modal.hide();

                        //TODO logout
                        return;
                    }

                    loading_modal.hide();
                    kissUtil.callNavigator("login/login.html");
                })
                .error(function (data, status, headers, config) {
                    kissUtil.alert('error','通信エラーが発生しました');
                    loading_modal.hide();
                    //TODO move to error page
                });
        }

        function callNavigator(page) {
            switch (page) {
                case 'behaviour':
                    app.navigator.pushPage('html/result/behaviour.html');
                    break;
                case 'communication':
                    app.navigator.pushPage('html/result/communication.html');
                    break;
                case 'problem':
                    app.navigator.pushPage('html/result/problem.html');
                    break;
                case 'derailment':
                    app.navigator.pushPage('html/result/derailment.html');
                    break;
                case 'salesConsulting':
                    app.navigator.pushPage('html/result/salesConsulting.html');
                    break;
                case 'letter':
                    app.navigator.pushPage('html/result/letter.html');
                    break;
                case 'stress':
                    app.navigator.pushPage('html/result/stress.html');
                    break;
                case 'ab_autonomy':
                    app.navigator.pushPage('html/result/ab_autonomy.html');
                    break;
                case 'ab_consumer':
                    app.navigator.pushPage('html/result/ab_consumer.html');
                    break;
                case 'ab_safety':
                    app.navigator.pushPage('html/result/ab_safety.html');
                    break;
                case 'ab_society':
                    app.navigator.pushPage('html/result/ab_society.html');
                    break;
                default:
                    console_log('エラー');
                    break;
            }
        }

        function testBehaviour(page) {
            loading_modal.show();
            console_log('test behaviour');

            $http.post('report/behaviour/' + id)
                .success(function (data, status, headers, config) {
                    console_log(data);

                    if (data['hasError']) {
                        kissUtil.alert('',data['message']);
                        loading_modal.hide();

                        //TODO logout
                        return;
                    }

                    ResultData.setBehaviour(data);
                    loading_modal.hide();
                    callNavigator(page);
                })
                .error(function (data, status, headers, config) {
                    kissUtil.alert('error','通信エラーが発生しました');
                    loading_modal.hide();

                    //TODO move to error page
                });
        }

        function testAbility(page) {
            loading_modal.show();
            console_log('test ability');

            $http.post('report/ability/' + id)
                .success(function (data, status, headers, config) {
                    console_log(data);
                    if (data['hasError']) {
                        kissUtil.alert('',data['message']);
                        loading_modal.hide();

                        //TODO logout
                        return;
                    }

                    ResultData.setAbility(data);
                    loading_modal.hide();
                    callNavigator(page);
                })
                .error(function (data, status, headers, config) {
                    kissUtil.alert('error','通信エラーが発生しました');
                    loading_modal.hide();
                    //TODO move to error page
                });
        }

        function testDerailment(page) {
            loading_modal.show();
            console_log('test derailment');

            $http.post('report/derailment/' + id)
                .success(function (data, status, headers, config) {
                    console_log(data);

                    if (data['hasError']) {
                        kissUtil.alert('',data['message']);
                        loading_modal.hide();

                        //TODO logout
                        return;
                    }

                    ResultData.setDerailment(data);
                    loading_modal.hide();
                    callNavigator(page);
                })
                .error(function (data, status, headers, config) {
                    kissUtil.alert('error','通信エラーが発生しました');
                    loading_modal.hide();
                    //TODO move to error page
                });
        }

        function testSalesConsulting(page) {
            loading_modal.show();
            console_log('test salesconsulting');

            $http.post('report/salesconsulting/' + id)
                .success(function (data, status, headers, config) {
                    console_log(data);

                    if (data['hasError']) {
                        kissUtil.alert('',data['message']);
                        loading_modal.hide();

                        //TODO logout
                        return;
                    }

                    ResultData.setSalesConsulting(data);
                    loading_modal.hide();
                    callNavigator(page);
                })
                .error(function (data, status, headers, config) {
                    kissUtil.alert('error','通信エラーが発生しました');
                    loading_modal.hide();
                    //TODO move to error page
                });
        }

        function testLetter(page) {
            loading_modal.show();
            console_log('test letter');

            $http.post('report/letter/' + id)
                .success(function (data, status, headers, config) {
                    console_log(data);

                    if (data['hasError']) {
                        kissUtil.alert('',data['message']);
                        loading_modal.hide();

                        //TODO logout
                        return;
                    }

                    ResultData.setLetter(data);
                    loading_modal.hide();
                    callNavigator(page);
                })
                .error(function (data, status, headers, config) {
                    kissUtil.alert('error','通信エラーが発生しました');
                    loading_modal.hide();
                    //TODO move to error page
                });
        }

        function testStress(page) {
            loading_modal.show();
            console_log('test stress');

            $http.post('report/stress/' + id)
                .success(function (data, status, headers, config) {
                    console_log(data);

                    if (data['hasError']) {
                        kissUtil.alert('',data['message']);
                        loading_modal.hide();

                        //TODO logout
                        return;
                    }

                    ResultData.setStress(data);
                    loading_modal.hide();
                    callNavigator(page);
                })
                .error(function (data, status, headers, config) {
                    kissUtil.alert('error','通信エラーが発生しました');
                    loading_modal.hide();
                    //TODO move to error page
                });
        }

    }

}());