(function() {

    angular
        .module('App')
        .factory('ResultData', ResultDataFactory);

    ResultDataFactory.$inject = ['$http','$q'];

    function ResultDataFactory($http, $q) {

        var resultData = {};
        resultData.behaviour = {};
        resultData.ability = {};
        resultData.derailment = {};
        resultData.salesConsulting = {};
        resultData.letter = {};
        resultData.stress = {};
        resultData.reportStatus = {};

        resultData.setBehaviour = function(data) {
            resultData.behaviour = data;
        };

        resultData.setAbility = function(data) {
            resultData.ability = data;
        };

        resultData.setDerailment = function(data) {
            resultData.derailment = data;
        };

        resultData.setSalesConsulting = function(data) {
            resultData.salesConsulting = data;
        };

        resultData.setLetter = function(data) {
            resultData.letter = data;
        };

        resultData.setStress = function(data) {
            resultData.stress = data;
        };

        resultData.setReportExist = function(data) {
            resultData.reportExist = data;
        };

        return resultData;
    }

}());