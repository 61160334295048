var KissUtil = function() {
    var program_questionnaire = undefined;
    var diagnosis_program = undefined;
    var program = undefined;
    var examinee_name = 'Unknown';

    this.alert = function(title, message, fn) {
        var callback = fn ? fn:function() {};
        ons.notification.alert({
            title: title,
            message: message,
            buttonLabel: 'OK',
            animation: 'default', // or 'none'
            callback: callback
        });
    };

    this.alertConfirm = function(message, fn) {
        ons.notification.confirm({
            title: '',
            message: message,
            buttonLabels: ['CANCEL', 'OK'],
            animation: 'default', // もしくは'none'
            primaryButtonIndex: 1,
            cancelable: true,
            callback: function(index) {
                // -1: キャンセルされた
                // 0-: 左から0ではじまるボタン番号
                if(index == 0) {
                    console_log('CANCEL');
                } else if(index == 1) {
                    console_log('OK');
                    fn();
                } else {
                    console_log("else");
                }
            }
        });
    };

    this.backToSelectProgram = function() {
        var fn = function() {
            app.navigator.replacePage('html/selectProgram.html', {animation: 'lift'});
        };
        kissUtil.alertConfirm('プログラム選択の一覧に戻ります。よろしいですか？', fn);
    };

    this.setName = function(n) {
        examinee_name = n;
    };

    this.getName = function() {
        return examinee_name;
    };

    this.setProgram = function(p) {
        program = p;
    };

    this.getProgram = function() {
        return program;
    };

    this.setProgramQuestionnaire = function(pq) {
        program_questionnaire = pq;
    };

    this.setDiagnosisProgram = function(dq) {
        diagnosis_program = dq;
    };

    this.getProgramQuestionnaire = function() {
        return program_questionnaire;
    };

    this.getDiagnosisProgram = function() {
        return diagnosis_program;
    };

    this.callNavigator = function(page) {
        loading_modal.show();
        setTimeout(function() {
            var destination = "html/" + page;
            var options = {};
            options.animation = 'lift';
            options.onTransitionEnd = function() {
                loading_modal.hide();
            };

            console_log(destination);
            app.navigator.replacePage(destination, options);
        }, 800);
    };

    this.clearStorage = function() {
        localStorage.clear();
        ons.notification.alert({
            title: "ログアウトしました",
            message: "ご利用ありがとうございました",
            buttonLabel: 'OK',
            animation: 'default', // or 'none'
            callback: function() {
                location.reload();
            }
        });
    };

};

var kissUtil = new KissUtil();
