/***
    login.html
        ng-controller = 'Login'
***/

(function() {

    angular
        .module('App')
        .controller('Login', LoginController);

    LoginController.$inject = ['$http','$scope','$timeout', 'kissApiService'];

    function LoginController($http, $scope, $timeout, kissApiService) {
        var vm = $scope;

        vm.input_id = "";
        vm.input_pass = "";

        vm.onclick_login = login;

        function login() {
            console_log('login');

            //TODO input validation check
            //checkInputData();

            loading_modal.show();
            $http.post('auth/login', {
                    data: {
                        'email': vm.input_id,
                        'password': vm.input_pass
                    }
                })
                .success(function (data, status, headers, config) {
                    console_log(data);
                    if (!data['login']) {
                        kissUtil.alert('',data['message']);
                        vm.input_pass = "";
                        loading_modal.hide();
                        return;
                    }
                    kissUtil.setName(data['examineeName']);
                    loading_modal.hide();
                    kissUtil.callNavigator("selectProgram.html");
                })
                .error(function (data, status, headers, config) {
                    kissUtil.alert('error','通信エラーが発生しました');
                    loading_modal.hide();

                    //TODO move to error page
                });
        }
    }

}());
