/**
 * Created by KazukiSugita on 2016/02/08.
 */

(function() {

    angular
        .module('App')
        .controller('ResultStress', ResultStressController);

    ResultStressController.$inject = ['$http', '$scope','$timeout', 'ResultData'];

    function ResultStressController($http, $scope, $timeout, ResultData) {
        var view = $scope;

        setData();

        function setData() {

            var data = ResultData.stress;

            view.S1 = data['S1'];
            view.S2 = data['S2'];
        }
    }

}());