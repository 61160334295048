/***
 * html/diagnosis_questionnaire.html
 * ng-controller = 'DiagnosisQuestionnaire'
 ***/

(function() {

    angular
        .module('App')
        .controller('DiagnosisQuestionnaire', DiagnosisQuestionnaireController);

    DiagnosisQuestionnaireController.$inject = ['$scope', '$http', '$timeout', 'InputCheckService'];

    function DiagnosisQuestionnaireController($scope, $http, $timeout, InputCheckService) {
        var questionnaireHtml = '<div class="questionnaire_items"></div>';
        var view = $scope;
        var questionnaireData = [];
        var answers = [];

        prepareQuestionnaire();

        function prepareQuestionnaire() {
            console_log('prepare questionnaire');

            var id = kissUtil.getProgramQuestionnaire().id;

            loading_modal.show();
            $http.post('quest/questionnaire/' + id)
                .success(function (data, status, headers, config) {
                    console_log(data);

                    if (data['hasError']) {
                        kissUtil.alert('',data['message']);
                        loading_modal.hide();

                        //TODO logout
                        return;
                    }

                    questionnaireData = data['data'];
                    prepareHtml(questionnaireData);
                    view.onclick_submit = submitAnswer;
                    loading_modal.hide();
                })
                .error(function (data, status, headers, config) {
                    kissUtil.alert('error','通信エラーが発生しました');
                    loading_modal.hide();

                    //TODO move to error page
                });
        }

        function prepareHtml(data) {
            $questionnaire_items = $(questionnaireHtml);
            data.forEach(function(item, index, array) {
                switch (item['type']) {
                    case 'date':
                        $questionnaire_items.append(getTypeDateHtml(index, item['params']));
                        break;
                    case 'text':
                        $questionnaire_items.append(getTypeTextHtml(index, item['params']));
                        break;
                    case 'radio':
                        $questionnaire_items.append(getTypeRadioHtml(index, item['params']));
                        break;
                }
            });

            $('.pageContents').append($questionnaire_items.html());
            var $btn = $('<ons-button modifier="quiet" class="buttonpositive mt20" ng-click="onclick_submit()">送信する</ons-button>');
            $btn.appendTo($('.pageContents'));
            ons.compile($btn[0]);
        }

        function getTypeDateHtml(orderIdx, params) {
            var html =
                '<dl id="questionnaire' + orderIdx + '" class="mt20 typeDate detailBasic"> ' +
                    '<dt class="detailBasic__title">' + params['title'] + '</dt> ' +
                    '<dd class="detailBasic__item mt20"><input type="date" class="form__textDate" placeholder="' + params['placeholder'] + '" ng-model="' + params['model_name'] + '"></dd> ' +
                '</dl> ';

            return html;
        }

        function getTypeRadioHtml(orderIdx, params) {
            var html =
                '<ul id="questionnaire' + orderIdx + '" class="radioButtonSet mt20 typeRadio">' +
                '</ul>';
            var $radio = $(html);

            var radioItems = params['radioItems'];
            radioItems.forEach(function(item, index, array) {
                var tmpHtml =
                    '<li> ' +
                        '<input type="radio" id="item' + index + '" name="' + params['name'] + '" ng-model="' + params['model_name'] + '" value="' + item['value'] + '">' +
                        '<label class="radio-button" for="item' + index + '" data-label="' + item['data_label'] + '">' + item['data_label'] + '</label>' +
                    '</li>';
                $radio.append(tmpHtml);
            });

            return $radio.prop('outerHTML');
        }

        function getTypeTextHtml(orderIdx, params) {
            var html =
                '<p id="questionnaire' + orderIdx + '" class="mt20 typeText">' +
                    '<input type="text" class="form__textBox" placeholder="' + params['placeholder'] + '" ng-model="' + params['model_name'] + '">' +
                '</p>';

            return html;
        }

        function submitAnswer() {
            console_log('submit answer');

            kissUtil.alertConfirm('回答を送信しますか？', function() {
                prepareAnswer(questionnaireData);

                var id = kissUtil.getProgramQuestionnaire().id;

                loading_modal.show();
                $http.post('quest/answer/' + id, {
                        data: answers
                    })
                    .success(function (data, status, headers, config) {
                        console_log(data);

                        if (data['hasError']) {
                            kissUtil.alert('',data['message']);
                            loading_modal.hide();

                            //TODO logout
                            return;
                        }

                        var diagnosis_program = kissUtil.getDiagnosisProgram();
                        loading_modal.hide();
                        if (diagnosis_program != undefined) {
                            //alert('診断項目 ' + diagnosis_program.name + ' への答えを開始する');
                            kissUtil.callNavigator("diagnosis/description.html");
                        } else {
                            kissUtil.callNavigator("diagnosis/end.html");
                        }
                    })
                    .error(function (data, status, headers, config) {
                        kissUtil.alert('error','通信エラーが発生しました');
                        loading_modal.hide();

                        //TODO move to error page
                    });
            });
        }

        function prepareAnswer(data) {
            //TODO input validation check
            //checkInputData();

            data.forEach(function(item, index, array) {
                var $questionnaire = $('#questionnaire' + index);
                var input;
                switch (item['type']) {
                    case 'date':
                        input = $questionnaire.find('input[type="date"]');
                        break;
                    case 'text':
                        input = $questionnaire.find('input[type="text"]');
                        break;
                    case 'radio':
                        input = $questionnaire.find('input[type="radio"]:checked');
                        break;
                }
                answers[index] = input.val();
            });

            console_log(answers);
        }
    }

}());