/**
 * Created by KazukiSugita on 2016/02/08.
 */

(function() {

    angular
        .module('App')
        .controller('ResultCommunication', ResultCommunicationController);

    ResultCommunicationController.$inject = ['$http', '$scope','$timeout', 'ResultData'];

    function ResultCommunicationController($http, $scope, $timeout, ResultData) {
        var view = $scope;
        setData();

        function setData() {
            var data = ResultData.ability;

            view.communication_comments = data['communication_comments'];
            view.communication_params = data['communication_params'];
            view.communication_related_behaviour_params = {};

            var related = data['communication_related_behaviour_params'];
            Object.keys(related).forEach(function(key) {
                view.communication_related_behaviour_params[key] = related[key]*10;
            });
        }
    }

}());