/**
 * Created by weiyuanpan on 2/2/16.
 */

(function() {

    angular
        .module('App')
        .controller('DiagnosisEnd', DiagnosisEndController);

    DiagnosisEndController.$inject = ['$http','$q','$scope','$timeout','ResultData','kissApiService'];

    function DiagnosisEndController($http, $q, $scope, $timeout, ResultData, kissApiService) {
        //var $pageContents = $('.pageContents');
        var view = $scope;

        view.exist = true;
        view.onclick_logout = kissApiService.logout;
        view.onclick_moveToResultPage = moveToResultPage;

        getReportStatus();

        function moveToResultPage() {
            if (kissUtil.getProgram() == undefined) {
                kissUtil.alert('','今参加できるプログラムがありません');
                return;
            }

            app.navigator.pushPage('html/result/main.html');
        }

        function getReportStatus() {
            console_log('check reportExist');

            var program_id = kissUtil.getProgram().id;

            loading_modal.show();
            $http.post('check/report/' + program_id)
                .success(function (data, status, headers, config) {
                    console_log(data);

                    if (data['hasError']) {
                        kissUtil.alert('',data['message']);
                        view.exist = false;
                        loading_modal.hide();
                        //TODO logout
                        return;
                    }

                    var d = convertData(data);
                    if(d['hasReport']) {
                        ResultData.setReportExist(d);
                    } else {
                        view.exist = false;
                    }

                    loading_modal.hide();
                })
                .error(function (data, status, headers, config) {
                    kissUtil.alert('error','通信エラーが発生しました');
                    loading_modal.hide();
                    //TODO move to error page
                });
        }

        function convertData(data) {
            var hasReport = false;
            var d = {};

            for (var key in data) {
                if (data.hasOwnProperty(key)) {
                    if (data[key] == 1) {
                        if (!hasReport) {
                            hasReport = true;
                        }
                        d[key] = true;
                        
                        if (key == 'ability') {
                            var abilityItems = data['ability_items'];
                            d['ability_items'] = {};
                            for (var key2 in abilityItems) {
                                if (abilityItems.hasOwnProperty(key2)) {
                                    d['ability_items'][key2] = (abilityItems[key2] == 1);
                                }
                            }
                        }
                    } else {
                        if (key == 'ability_items') continue;
                        d[key] = false;
                    }
                }
            }

            d['hasReport'] = hasReport;
            return d;
        }
    }

}());