/*
(function() {
    var encrypted = CryptoJS.DES.encrypt("sugita", "kazuki");
    console_log("encrypted: " + encrypted);

    var decrypted = CryptoJS.DES.decrypt(encrypted, "kazuki");
    console_log("decrypted: " + decrypted);
}());

var encrypted = CryptoJS.DES.encrypt("The secret message", "secret_key");
    var e_msg = encrypted.toString();
    console_log(e_msg);

    var decrypted = CryptoJS.DES.decrypt(e_msg, "secret_key");
    var d_msg = decrypted.toString(CryptoJS.enc.Utf8);
    console_log(d_msg);
*/

var Cryption = function() {

    var _crypt_key = "lP0H5UcW5Q";

    this.encryption = function(obj) {
        var encrypted_obj = CryptoJS.DES.encrypt(obj, _crypt_key);
        return encrypted_obj;
    };



    this.decryption = function(obj) {
        var decrypted = CryptoJS.DES.decrypt(obj, _crypt_key);
        var decrypted_string = decrypted.toString(CryptoJS.enc.Utf8);
        return decrypted_string;
    };

};

var cryption = new Cryption();