var _env = 'production';
var console_log = function(data) {
    if (_env === 'dev') {
        console.log(data);
    }
};

(function() {

    angular
        .module('App',[
            'onsen',    //onsen
            'ngSanitize'
    ]);

    angular
        .module('App')
        .service('InputCheckService', function() {
            return {
                isFilled: function(array) {
                    var bool = true;
                    for(var i=0; i<array.length; i++) {
                        if(array[i] == "" || array[i] == undefined) {
                            kissUtil.alert("エラー","未入力があります");
                            bool = false;
                            break;
                        }
                    }
                    return bool;
                }
            }
        });

}());
